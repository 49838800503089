import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {setSiteSettings} from '../../../../commons/actions/site-settings'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetComponentDataParams, GetComponentDataResponse} from '../../shared-api'
import {updateNoEventsUrl} from './navigation'

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_COMPONENT_DRAFT = createActions('UPDATE_COMPONENT_DRAFT')

export const getComponentData = createAsyncAction<GetComponentDataResponse, GetComponentDataParams>(
  'GET_COMPONENT_DATA',
  async (params, {dispatch, extra: {serverApi}}) => {
    const component = await serverApi.getComponentData(params)

    dispatch(setSiteSettings({settings: component.siteSettings}))

    if (params.viewMode !== 'editor') {
      dispatch(updateNoEventsUrl())
    }

    return component
  },
)

export const updateComponentSettings = (settings: object) => (dispatch: Function) => {
  dispatch({type: UPDATE_SETTINGS, payload: settings})
  return dispatch(updateNoEventsUrl())
}

export const updateComponentDraft = (component: wix.events.editor.WebComponentConfig) =>
  callAPI(UPDATE_COMPONENT_DRAFT, component)
